<template>
  <div class="merlends">
    <el-form
      ref="form"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item>
        <el-input
          v-model="queryParams.goodsName"
          placeholder="商品名称/通用名"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.id"
          placeholder="编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.approvalNumber"
          placeholder="批准文号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.manufacturer"
          placeholder="生产厂家"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryParams.auditStatus"
          filterable
          placeholder="审核状态"
          clearable
          @change="handleQuery"
        >
          <el-option label="已通过" :value="2"></el-option>
          <el-option label="已驳回" :value="3"></el-option>
          <el-option label="待审核" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryParams.auditType"
          filterable
          placeholder="提交类型"
          clearable
          @change="handleQuery"
        >
          <el-option label="更新图片" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryParams.dateTime"
          type="daterange"
          range-separator="—"
          start-placeholder="提交开始日期"
          end-placeholder="提交结束日期"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery"> 查 询 </el-button>
        <el-button type="primary" @click="Reset"> 重 置 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="{ background: '#F2F3F5' }"
      border
    >
      <el-table-column prop="id" align="center" label="编号" width="100" />
      <el-table-column
        prop="createTime"
        align="center"
        label="提交时间"
        width="180"
      />
      <el-table-column prop="" align="center" label="提交人">
        <template slot-scope="scope">
          {{ scope.row.creatorName }} /{{ scope.row.creatorAccount }}</template
        >
      </el-table-column>
      <el-table-column prop="" align="center" label="提交类型">
        <template slot-scope="scope">
          {{ scope.row.auditType == 1 ? "更新图片" : "—" }}
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="商品信息" width="300px">
        <template slot-scope="scope">
          <div style="text-align: left">
            <div>商品名称：{{ scope.row.goodsName }}</div>
            <div>商品编号：{{ scope.row.goodsId }}</div>
            <div>批准文号：{{ scope.row.approvalNumber }}</div>
            <div>单位：{{ scope.row.goodsUnit }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="goodsCommonName"
        align="center"
        label="商品通用名"
      />
      <el-table-column prop="specification" align="center" label="规格" />
      <el-table-column prop="manufacturer" align="center" label="生产厂家" />
      <el-table-column prop="" align="center" label="审核状态">
        <template slot-scope="scope">
          {{ scope.row.auditStatus | audidsType }}
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="审核人">
        <template slot-scope="scope">
          <div v-if="scope.row.operatorName || scope.row.operatorAccount">
            {{ scope.row.operatorName }}/{{ scope.row.operatorAccount }}
          </div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <span
            class="btsed"
            v-if="scope.row.auditStatus == 1"
            @click="toexamine(scope.row)"
            >审核</span
          >
          <span class="btsed" @click="toSee(scope.row)">查看</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
  </div>
</template>

<script>
import {
  platformGoodsAuditlist, //商品资料审核列表
} from "@/api/apiAll/phpUrl";
export default {
  name: "",
  data() {
    return {
      // 查询入参
      queryParams: {
        page: 1, // 页码
        perPage: 10, //每页大小
        goodsName: "", //商品名称
        id: "", //ID编号
        approvalNumber: "", //批准文号
        manufacturer: "", //生产厂家
        auditStatus: "", //审核状态:1待审核;2审核通过;3审核驳回
        auditType: "", //提交类型:1更新图片
        startTime: "",
        endTime: "",
        dateTime: [],
      },
      total: 0,
      loading: false,
      tableData: [], // 列表
    };
  },
  filters: {
    audidsType(val) {
      switch (val) {
        case 1:
          return "待审核";
        case 2:
          return "已通过";
        case 3:
          return "已驳回";
        default:
          return "-";
      }
    },
  },
  async created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      await Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    if (this.$route.params.id) {
      await Object.assign(this.queryParams, this.$options.data().queryParams);
      this.queryParams.id = this.$route.params.id;
      // this.queryParams.goodsName = this.$route.params.goodsName;
      // this.queryParams.auditStatus = this.$route.params.auditStatus;
    }
    this.getList();
  },
  methods: {
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    // 列表数据
    getList() {
      this.loading = true;
      var data = { ...this.queryParams };
      if (data.dateTime) {
        data.startTime = data.dateTime[0];
        data.endTime = data.dateTime[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      platformGoodsAuditlist(data).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
      this.setquery();
    },
    //查询按钮操作
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置 */
    Reset() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getList();
    },
    /** 审核 */
    toexamine(row) {
      this.$router.push({
        path: "/platGoodication",
        query: { id: row.id, goodsId: row.goodsId },
      });
    },
    /** 查看 */
    toSee(row) {
      this.$router.push({
        path: "/platQualifidetails",
        query: { id: row.id, goodsId: row.goodsId },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
